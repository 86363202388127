<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}
      </h3>
    </div>

    <div class="box-body">
      <div class="box-body">
        <table-items :columns="columns"
                     :data_search="data_search"
                     :relations="relations"
                     component="notification-item"
                     :api_resource="'/employee/' + authUser.id + '/notifications'">
        </table-items>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import TableItems from './../../components/pagination/table-items';
import {mapGetters} from "vuex";

Vue.component('notification-item', (resolve) => {
  return require(['./item'], resolve);
});

export default {
  name: 'notifications',

  components: {
    TableItems,
  },

  props: {
    data_search: {
      type: String,
      required: false
    }
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data: () => ({
    page_title: 'Уведомления',

    columns: [
      {
        title: null,
        class: null,
        style: null,
      },
      {
        title: 'Сообщение',
        class: null,
        style: null,
      },
      {
        title: 'Дата',
        class: null,
        style: null,
      },
    ],

    relations: [],
  }),

  computed: {
    ...mapGetters("auth", ["authUser"]),
  },

  methods: {},
}
</script>

<style scoped>

</style>
